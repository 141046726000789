// Bulletin view

// Blocs d'en-têtes

.bulletin-header-left {
  border-left: 9px solid #d7d7d7;
  padding: 0 0 0 14px;
  line-height: 1.2em;

  p:last-child {
    margin-bottom: 0;
  }
}

.bulletin-header-right {
  @media (max-width: @plone-screen-sm-max) {
    margin-top: 2em;
  }
}

.bulletin-body {
}

// Multicolonnage

@media (min-width: @plone-screen-md-min) {
  .columns-2 {
    columns: 2;
  }

  .columns-3 {
    columns: 3;
  }
}

@media (min-width: @plone-screen-sm-min) {
  .columns-sm-2 {
    columns: 2;
  }

  .columns-sm-3 {
    columns: 3;
  }
}
