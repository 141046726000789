//
// Doormat
//

.oiml-doormat {

  margin-top: 20px;

  // Titres

  h2, h3 {
    text-align: center;
    color: black;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  // Ombre sur les images

  img {
    box-shadow: 0 0 20px #C7C7C7;
    -o-box-shadow: 0 0 20px #C7C7C7;
    -moz-box-shadow: 0 0 20px #C7C7C7;
    -webkit-box-shadow: 0 0 20px #C7C7C7;
  }
}
