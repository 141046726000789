//*// BREADCRUMBS //*//

.plone-breadcrumb {
  clear: left;
  height: 40px;
  background-color: @plone-breadcrumb-bg;
  margin-bottom: @plone-line-height-computed;
  margin-top: -@plone-line-height-computed;
  font-size: @plone-breadcrumb-font-size;
  font-weight: @plone-font-weight-regular;

  > * > span { //you are here
    display: inline-block;
    color: @plone-gray;
    padding: @plone-breadcrumb-padding-vertical 0;
    @media (max-width: @plone-screen-sm-min) {
      display: none;
    }
  }

  ol {
    display: inline-block;
    padding: @plone-breadcrumb-padding-vertical 0;
    list-style: none;
    margin: 0 0 0 8px;

    > li {
      font-weight: @plone-font-weight-regular;
      display: inline-block;
      line-height: 0; // match ol & span height
      position: relative;
      padding: 0 @plone-breadcrumb-padding-horizontal*2 0 0;

      + li::before {
        content: "⟩";
        padding: 0 5px;
        color: @plone-breadcrumb-color;
      }
    }

    a {
      color: @plone-link-color-on-grey;
    }

    #breadcrumbs-current {
      color: @plone-breadcrumb-active-color;
    }
  }

  /* uncomment to hide breadcrumbs on home
  body.section-front-page & {display: none;} //not breadcrumbs on homepage
  */
}
