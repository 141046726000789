//
// Our custom styles
//

body {
  font-weight: @plone-font-weight-regular; // Personnalisation de scaffolding.plone.less
  .use-montserrat;
}

// Titres : Montserrat bleu
h1, h2, h3, h4, h5, h6 {
  color: @rpix-blue;
}

h1 {
  font-size: 42px;
  font-weight: 300;
}

p.callout {
  background: @rpix-beige;
  border-left: 1em solid @rpix-gold;
  color: @rpix-dark-blue;
}

table, th {
  color: inherit; // Personnalisation de tables.plone.less
}

#content-header {
  background-image: url('../img/banner.png');
  background-position: 50% 46%;
}

#content-core {
  a {
    border-bottom: none;
  }
}
.documentFirstHeading {
  border-bottom: 1px solid @bleu-oiml;
  font-weight: 300;

  b {
    font-weight: 700;
  }

}

.ruler {
  width: 100%;
  margin-left: -15px;
  margin-bottom: 2em;
}

.btn-oiml {
  padding: 4px 1.5em;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 700;
  background-color: @rpix-blue;
  color: white;
  text-transform: uppercase;

  &:hover, &:focus{
    color: white;
  }
}

.form-control {
  color: @plone-input-color;
}

// TinyMCE templates

// image-grid-2x2:
.image-grid-2x2 {
  padding: 0;
  margin: 0 0 @plone-grid-gutter-width 0;
  list-style: none;
  .make-row();

  > li {
    .make-xs-column(12);
    .make-sm-column(6);
    margin-top: @plone-grid-gutter-width;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
