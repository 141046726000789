//
// Mixins
//

// Valeur responsive d'une propriété
.responsive-value(@property; @val-sm;@val-md;@val-lg ) {
  @media (min-width: @plone-screen-sm-min) {
      @{property} : @val-sm;
  }
  @media (min-width: @plone-screen-md-min) {
      @{property}: @val-md;
  }
  @media (min-width: @plone-screen-lg-min) {
      @{property}: @val-lg;
  }
}
