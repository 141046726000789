@import "utils";

.dropdown-box {
  position: relative;

  a {
    padding-right: 40px !important;
  }
}

.dropdown-button {
  position: absolute;
  top: 9px;
  right: 10px;

  display: block;

  margin: 0;
  padding: 0;

  width: 24px;
  height: 24px;

  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;

  background-color: transparent;

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    left: 7px;

    margin-top: -2px;

    .arrow-down(white);

  }

  &:focus,
  &:hover,
  &:active {
    background-color: white;
    border-color: white;

    &::after {
      border-top-color: @blue-dark;
    }
  }
}
