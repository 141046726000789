//
// CUSTOM MENU STYLE
//

@import "utils";

.megamenu-panel {

  position: absolute;
  display: none;
  @media (max-width: @plone-screen-xs-max) {
    left: 5%;
    width: 90%;
  }
  @media (min-width: @plone-screen-sm-min) {
    left: 0;
    width: 100%;
  }
  background-color: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  font-weight: normal;

  &.open {
    display: block;
  }

  > div {
    .responsive-value(width; (@megamenu-width-sm/@megamenu-nb-cols); (@megamenu-width-md/@megamenu-nb-cols); (@megamenu-width-lg/@megamenu-nb-cols));
    border: 0 solid transparent;

    @media (max-width: @plone-screen-xs-max) {
      padding: 0.5em 0;
      &:first-child {
        padding-top: 1em;
      }
      &:last-child {
        padding-bottom: 1em;
      }
    }

    @media (min-width: @plone-screen-sm-min) {
      padding: @plone-grid-gutter-width 0;

      &:not(:first-child)::after {
        content: '';
        position: absolute;
        top: @plone-grid-gutter-width;
        bottom: @plone-grid-gutter-width;
        width: 1px;
        background-color: @rpix-blue;
      }
    }
  }

  li {
    display: block;

    a {
      display: block;

      &:hover, &:active {
        background-color: @rpix-beige;
        text-decoration: none;
      }
    }
  }
}

// + + + + + + + + + + + + + + + + + + + + + + + +
// UL LEVEL 1
// + + + + + + + + + + + + + + + + + + + + + + + +

.navTreeLevel0 {

  @media (max-width: @plone-screen-xs-max) {
    padding: 0;
  }
  @media (min-width: @plone-screen-sm-min) {
    padding: 0 (@plone-grid-gutter-width / 2);
  }

  > li {

    padding: (@plone-grid-gutter-width / 2) 0 0 0;

    &:first-child {
      padding-top: 0;
    }

    > a {
      padding: 2px 2px 2px (@plone-grid-gutter-width / 2);
      color: @rpix-blue;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .dropdown-button {
    display: none;
  }

  .slidetoggle-button {
    top: 0;
    border-color: @gray-2;
    background-color: @gray-2;

    &::after {
      .arrow-down(@black);
    }

    &:focus,
    &:hover,
    &:active {
      background-color: @gray-2;
      border-color: @gray-2;

      &::after {
        border-top-color: @black;
      }
    }
  }
}

// + + + + + + + + + + + + + + + + + + + + + + + +
// UL LEVEL 2
// + + + + + + + + + + + + + + + + + + + + + + + +

.navTreeLevel1 {

  padding-top: 5px;
  padding-left: 0;

  li {
    padding: 4px 0 0 0;
  }

  a {
    padding: 2px;
    padding-left: (@plone-grid-gutter-width / 2) + 20px;
    font-size: 14px;
    color: black;
  }
}

// + + + + + + + + + + + + + + + + + + + + + + + +
// UL LEVEL 3
// + + + + + + + + + + + + + + + + + + + + + + + +

.navTreeLevel2 {
  padding-left: 0;

  li {
    a {
      padding-left: (@plone-grid-gutter-width / 2) + 40px;
      font-size: 13px;
    }
  }
}
