/*
 Utilities
 */

// Colours

@black: #020B12;

@gray: #ccc;
@gray-2: #ddd;
@gray-3: #F7F7F7;

@blue: #5986B0;
@blue-dark: #2E5477;
@blue-dark-2: #213C54;
@blue-dark-3: #4b78a2;

@bleu-oiml: #366797;


// Mixins

.clear() {

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.img-fluide() {

  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.arrow-down(@color : white) {

  width: 0;
  height: 0;

  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid @color;

  transform-origin: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
