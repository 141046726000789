// Full-width layout

// Variables

@fullwidth-spacing-x: 30px; // px obligatoirement
@fullwidth-spacing-y: 60px;

// Version mobile (xs)
@fullwidth-spacing-x-xs: 20px; // px obligatoirement
@fullwidth-spacing-y-xs: 30px;

// Styles

.full-width {

  .Beige, .beige {
    background-color: @rpix-beige;
  }

  .frontpage-section {
    padding: @fullwidth-spacing-y-xs 0;
    @media (min-width: @plone-screen-sm-min) {
      padding: @fullwidth-spacing-y 0;
    }

    > .container {
      h1:first-child, h2:first-child {
        margin-top: 0;
      }
    }
  }

  .frontpage-section.White {
    + .frontpage-section.White {
      padding-top: 0;
    }
  }

  .frontpage-section.Beige {
    + .frontpage-section.Beige {
      padding-top: 0;
    }
  }

  > .frontpage-slot:first-child {
    .frontpage-section.White:first-child {
      padding-top: 0;
    }
  }

  > #frontpage-slot-feat-w-2 {
    + #frontpage-slot-2 {
      .frontpage-section.White:first-child {
        padding-top: 0;
      }
    }
  }

  .portlet-slot {
    padding-top: @fullwidth-spacing-y-xs;
    @media (min-width: @plone-screen-sm-min) {
      padding-top: @fullwidth-spacing-y;
    }

    > .container {
      > .row {

        margin-left: -@fullwidth-spacing-x-xs;
        margin-right: -@fullwidth-spacing-x-xs;
        @media (min-width: @plone-screen-sm-min) {
          margin-left: -@fullwidth-spacing-x;
          margin-right: -@fullwidth-spacing-x;
        }

        > div {
          padding: 0 @fullwidth-spacing-x-xs;
          @media (min-width: @plone-screen-sm-min) {
            padding: 0 @fullwidth-spacing-x;
          }
        }
      }
    }
  }

  > #frontpage-slot-feat-w {
    .portlet-slot {
      padding-top: 0;
    }
  }

  .container {

    > h1 {
      margin-top: 0;
    }

    .heading-left {
      color: @rpix-blue;
      font-size: 17px;
      font-weight: 700;
      line-height: 1.2em;
    }

  }
}


// Corrections sur la page d'accueil

body.section-front-page {

  #main-container {
    article {
      margin-bottom: 0;
    }
  }
}
