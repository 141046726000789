//
// Misc. Portlet settings
//

// Beige portlet with logos

.frontpage-section.frontpage-section-1.Beige {
  .frontpage-logos {
    img {
      width: 33.3333%;
      @media (min-width: @plone-screen-sm-min) {
        width: 50%;
      }
    }
  }
}
