/*
 theme.less file that will be compiled
 */

// ### PLONE IMPORTS ###

@barceloneta_path: "../barceloneta/less";

//*// Core variables and mixins
@import "@{barceloneta_path}/fonts.plone.less";
@import "variables.plone.less";
@import "@{barceloneta_path}/mixin.prefixes.plone.less";
@import "@{barceloneta_path}/mixin.tabfocus.plone.less";
@import "@{barceloneta_path}/mixin.images.plone.less";
@import "@{barceloneta_path}/mixin.forms.plone.less";
@import "@{barceloneta_path}/mixin.borderradius.plone.less";
@import "@{barceloneta_path}/mixin.buttons.plone.less";
@import "@{barceloneta_path}/mixin.clearfix.plone.less";
@import "@{barceloneta_path}/mixin.gridframework.plone.less"; //grid Bootstrap
@import "@{barceloneta_path}/mixin.grid.plone.less"; //grid Bootstrap
@import "@{barceloneta_path}/mixin.portlets.plone.less";
// Customized mixin for barceloneta fonts
@import "mixin.fonts.plone.less";


@import "@{barceloneta_path}/normalize.plone.less";
@import "@{barceloneta_path}/print.plone.less";
@import "@{barceloneta_path}/code.plone.less";

//*// Core CSS
@import "@{barceloneta_path}/grid.plone.less";
@import "@{barceloneta_path}/scaffolding.plone.less";
@import "@{barceloneta_path}/type.plone.less";
@import "tables.plone.less";
@import "@{barceloneta_path}/forms.plone.less";
@import "@{barceloneta_path}/buttons.plone.less";
@import "@{barceloneta_path}/states.plone.less";

//*// Components
@import "breadcrumbs.plone.less";
@import "@{barceloneta_path}/pagination.plone.less";
@import "@{barceloneta_path}/formtabbing.plone.less"; //pattern
@import "@{barceloneta_path}/views.plone.less";
@import "@{barceloneta_path}/thumbs.plone.less";
@import "@{barceloneta_path}/alerts.plone.less";
@import "portlets.plone.less";
@import "@{barceloneta_path}/controlpanels.plone.less";
@import "@{barceloneta_path}/tags.plone.less";
@import "@{barceloneta_path}/contents.plone.less";

//*// Patterns
@import "@{barceloneta_path}/accessibility.plone.less";
@import "@{barceloneta_path}/toc.plone.less";
@import "@{barceloneta_path}/dropzone.plone.less";
@import "@{barceloneta_path}/modal.plone.less";
@import "@{barceloneta_path}/pickadate.plone.less";
@import "@{barceloneta_path}/sortable.plone.less";
@import "@{barceloneta_path}/tablesorter.plone.less";
@import "@{barceloneta_path}/tooltip.plone.less";
@import "@{barceloneta_path}/tree.plone.less";
@import "@{barceloneta_path}/dropdowns.plone.less";

//*// Structure
@import "header.plone.less";
@import "sitenav.plone.less";
@import "sitenav.myaccess.less";
@import "@{barceloneta_path}/main.plone.less";
@import "@{barceloneta_path}/footer.plone.less";
@import "@{barceloneta_path}/loginform.plone.less";
@import "@{barceloneta_path}/sitemap.plone.less";

//*// Products
@import "@{barceloneta_path}/event.plone.less";
@import "@{barceloneta_path}/image.plone.less";
@import "@{barceloneta_path}/behaviors.plone.less";
@import "@{barceloneta_path}/discussion.plone.less";
@import "@{barceloneta_path}/search.plone.less";

// ### END OF PLONE IMPORTS ###


// ### UTILS ###

// import bootstrap files:
@bootstrap_path: "../node_modules/bootstrap/less";

// Enable as needed
// @import "@{bootstrap_path}/mixins/grid-framework.less";
@import "@{bootstrap_path}/mixins/background-variant.less";
@import "@{bootstrap_path}/mixins/alerts.less";
@import "@{bootstrap_path}/mixins/labels.less";
@import "@{bootstrap_path}/mixins/nav-vertical-align.less";
@import "@{bootstrap_path}/mixins/panels.less";
@import "@{bootstrap_path}/mixins/responsive-visibility.less";
@import "@{bootstrap_path}/mixins/text-emphasis.less";
@import "@{bootstrap_path}/mixins/text-overflow.less";
@import "@{bootstrap_path}/mixins/vendor-prefixes.less";
@import "@{bootstrap_path}/alerts.less";
@import "@{bootstrap_path}/variables.less";
// @import "@{bootstrap_path}/mixins.less";
// @import "@{bootstrap_path}/utilities.less";
@import "@{bootstrap_path}/responsive-embed.less";
@import "@{bootstrap_path}/responsive-utilities.less";
// @import "@{bootstrap_path}/grid.less";
@import "@{bootstrap_path}/type.less";
@import "@{bootstrap_path}/buttons.less";
@import "@{bootstrap_path}/forms.less";
//@import "@{bootstrap_path}/labels.less";
@import "@{bootstrap_path}/modals.less";
@import "@{bootstrap_path}/navs.less";
@import "@{bootstrap_path}/navbar.less";
@import "@{bootstrap_path}/panels.less";
// @import "@{bootstrap_path}/carousel.less";

// ### END OF UTILS ###

.form-group {
  padding-top: 10px;
  clear: both;
}


// include our custom css/less
@import "fonts";
@import "custom";
@import "lists";
@import "bs3.labels.less";
@import "bs4.spacers.less";
@import "sitenav.plone.megamenu.less";
@import "oiml.document.less";
@import "oiml.bulletin.less";
@import "oiml.fullwidth.less";
@import "oiml.mixins.less";
@import "oiml.portlet.feature.less";
@import "oiml.portlet.footer.less";
@import "oiml.portlet.misc.less";
@import "oiml.plone4.less";
@import "oiml.siteactions.less";
@import "oiml.doormat.less";
@import "oiml.footer.less";

// Hack pour la transition d'oiml.members vers Plone 5
@import "sunburst.columns.less";
