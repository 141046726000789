//
// Compatibilité avec les contenus Plone 4
//

#content-core {

  // "Invisible" a une signification différente dans Plone 4

  .invisible {
    visibility: initial;
  }

  table.invisible {
    th, td {
      border-width: 0;
    }

    border-width: 0;
  }
}
