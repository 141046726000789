// Bloc "site actions" + "portal-anontools" + "portal-membertools"

@siteactions-font-size: 13px;

.site-actions {
  margin-bottom: 0;
  border-radius: 0;
  min-height: 40px;
  background: @plone-siteactions-bg;
  font-size: @siteactions-font-size;
  text-transform: uppercase;

  #site-actions-collapse {
    margin-left: -15px;

    > ul, > span > ul {
      > li {
        > a {
          height: 39px;
          padding-top: 9px;
          color: white;

          &:hover,
          &:active {
            background-color: @plone-siteactions-hover-bg;
            text-decoration: none;
          }
        }
      }
    }

    @media (max-width: @plone-screen-xs-max) {
      > span {
        display: flex;
        padding-top: 10px;

        > ul {
          margin: 0 7.5px 6px -7.5px;

          > li {
            > a {
              height: 24px;
              padding: 0 7.5px;
            }
          }
        }
      }
      #portal-searchbox {
        padding-top: 4px;
      }
      .navbar-nav.navbar-right {
        margin: -1px -15px;
      }
    }
  }

  ul {
    li {
      a {
        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  input[type="text"] {
    background: @rpix-dark-blue;
    color: white;
  }

  button[type="submit"] {
    background-color: @rpix-blue;
    color: white;
  }

  svg.fa-user {
    margin-left: 0.5em;
  }

  .portal-membertools {
    padding-right: (@plone-grid-gutter-width / 2);
    margin-right: (@plone-grid-gutter-width / 2);

    > ul.dropdown-menu {
      margin-right: (@plone-grid-gutter-width / 2);
      //text-transform: initial;
      display: block;
      opacity: 0;
      visibility: hidden;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: @rpix-blue;
      transition: ease-out .35s;

      a {
        padding: @plone-sitenav-link-padding;
        color: white;
        font-size: @siteactions-font-size;
        text-transform: none;

        &:hover {
          background-color: @rpix-gold;
        }
      }
    }

    &:hover {
      > ul.dropdown-menu {
        opacity: 1;
        visibility: visible;
        transition: ease-in .35s;
      }
    }
  }

  #portal-myaccess {
    padding-left: 0;
    background-color: @plone-sitenav-bg;

    > li {
      > a {
        padding-top: 8px;
        background-color: @rpix-gold;
        pointer-events: none;
      }
    }
  }
}
