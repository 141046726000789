//
// Footer Portlet
//

// Variables

@pv2-height: 270px;
@pv2-padding: 10px;

@pv2-img-height-xs: 80px;
@pv2-img-height-sm: 100px;
@pv2-img-height-md: 125px;
@pv2-img-height-lg: 150px;

// Heuteur du bloc de titre
@pv2-header-height-xs: 60px;
@pv2-header-height-sm: 60px;
@pv2-header-height-md: 75px;
@pv2-header-height-lg: 85px;

// Taille du titre
@pv2-heading-size-xs: 14px;
@pv2-heading-size-sm: 15px;
@pv2-heading-size-md: 21px;
@pv2-heading-size-lg: 24px;

// Taille du texte du bouton
@pv2-btn-font-size-xs: 11px;
@pv2-btn-font-size-sm: 11px;
@pv2-btn-font-size-md: 16px;
@pv2-btn-font-size-lg: 18px;


// Styles
.footer-portlet-wrapper {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.6));

  .footer-portlet {
    padding: 0;
    @media (max-width: @plone-screen-xs-max) {
      margin-bottom: @fullwidth-spacing-y-xs;
    }
    @media (min-width: @plone-screen-sm-min) {
      margin-bottom: @fullwidth-spacing-y;
    }

    .image {
      height: @pv2-img-height-xs;
      .responsive-value(height; @pv2-img-height-sm; @pv2-img-height-md; @pv2-img-height-lg);
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .body {
      padding: @pv2-padding;
      background-color: @rpix-blue;
      height: @pv2-header-height-xs;
      .responsive-value(height; @pv2-header-height-sm; @pv2-header-height-md; @pv2-header-height-lg);

      h2 {
        margin: 5px 0;
        font-size: @pv2-heading-size-xs;
        .responsive-value(font-size; @pv2-heading-size-sm; @pv2-heading-size-md; @pv2-heading-size-lg);
        color: white;
        font-weight: 500;
      }
    }

    .footer {
      clip-path: polygon(0 0, 100% 0, 84% 100%, 0 100%, 0 0);
      @media (min-width: @plone-screen-md-min) {
        clip-path: polygon(0 0, 100% 0, 86% 100%, 0 100%, 0 0);
      }
      @media (min-width: @plone-screen-lg-min) {
        clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%, 0 0);
      }
      padding: 0 @pv2-padding @pv2-padding @pv2-padding;
      background-color: @rpix-blue;
    }

    .btn-oiml {
      background-color: @rpix-gold;
      font-size: @pv2-btn-font-size-xs;
      .responsive-value(font-size; @pv2-btn-font-size-sm; @pv2-btn-font-size-md; @pv2-btn-font-size-lg);
    }

  }
}


// Ajustements pour la version française

html[lang="fr"] {
  .btn-oiml {
    padding: 4px 1em;
  }
}
