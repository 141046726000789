// Custom document view

// Variables
@document-left-heading-image-height-xs: 200px;
@document-left-heading-image-height-sm: 250px;
@document-left-heading-image-height-md: 250px;
@document-left-heading-image-height-lg: 250px;

h2.document-description {
  margin: 0 0 1em 55px;
  padding-left: 75px;
  min-height: 1.5em;

  @media(max-width: @plone-screen-xs-max) {
    font-size: 21px;
  }

  background-image: url('../img/blue-arrow.png');
  background-position-y: -10px;
  background-size: 55px auto;
  background-repeat: no-repeat;

  font-weight: 600;
}

.document-header-left {
  font-size: 17px;
  font-weight: 600;
  color: @rpix-blue;

  .image {
    max-height: @document-left-heading-image-height-xs;
    .responsive-value(max-height; @document-left-heading-image-height-sm; @document-left-heading-image-height-md; @document-left-heading-image-height-lg);
    overflow: hidden;
    margin-bottom: 1em;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .body {
    padding-left: 55px;
  }
}

.document-body-right {
  ul {
    padding-left: 20px;
  }
}
