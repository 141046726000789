//
// Footer
//

#portal-footer-wrapper {

  padding: 0;

  #portal-footer {

    .footer-actions {
      background-color: @rpix-gold;
      @media (max-width: @plone-screen-xs-max) {
        padding: 0.75em 0;
      }
      text-align: center;
      font-size: 12px;

      ul {
        @media (max-width: @plone-screen-xs-max) {
          list-style: none;
        }
        @media (min-width: @plone-screen-sm-min) {
          display: flex;
          flex-direction: row;
        }
        padding: 0;
        margin-bottom: 0;
        text-transform: uppercase;

        li {
          @media (max-width: @plone-screen-xs-max) {
            padding: 0.5em 30px;
          }
          @media (min-width: @plone-screen-sm-min) {
            flex: auto;
            padding: 12px 1em;
            display: inline-block;
            min-height: 40px;
            border-left: 1px solid white;
            padding-bottom: 6px;

            &:last-child {
              border-right: 1px solid white;
            }
          }

          a {
            color: white;
          }
        }
      }
    }

    .colophon {
      @media (max-width: @plone-screen-xs-max) {
        padding: 20px;
      }
      @media (min-width: @plone-screen-sm-min) {
        padding-top: 25px;
        padding-bottom: 18px;
      }
      @media (min-width: @plone-screen-md-min) {
        height: 140px;
        padding: 35px;
      }
      background-color: @rpix-blue;

      .logo {
        margin-top: -8px;
        width: 250px;
      }

      a {
        color: white;
      }

      .social-links {
        font-size: 75%;

        .fa-youtube {
          font-size: 4.6em;
          margin-right: 30px;
          vertical-align: -10%;
        }

        .fa-linkedin {
          font-size: 4em;
          margin-right: 20px;
        }

        .fa-facebook-f {
          font-size: 1.5em;
          color: #1b4870ed;
          margin-top: 19%
        }
      }
    }

    .signature {
      padding: .75em 0;
      background-color: @rpix-dark-blue;
      font-size: 11px;

      // Blocs centrés uniquement en XS
      @media (max-width: @plone-screen-xs-max) {
        text-align: center;
      }
      // Saut à la ligne des <span> en XS et SM
      @media (max-width: @plone-screen-sm-max) {
        span {
          display: block;
        }
      }
      // Affichage des <div> côte-à-côte en SM, MD, LG
      @media (min-width: @plone-screen-sm-min) {
        > div {
          display: flex;

          > div {
            flex: auto;
          }
        }
      }
    }
  }
}
