// Lists

// Style a), b), c), ...
// Source : https://www.w3.org/TR/predefined-counter-styles/#latin-styles

@counter-style letter-rparen {
  system: alphabetic;
  symbols: 'a' 'b' 'c' 'd' 'e' 'f' 'g' 'h' 'i' 'j' 'k' 'l' 'm' 'n' 'o' 'p' 'q' 'r' 's' 't' 'u' 'v' 'w' 'x' 'y' 'z';
  suffix: ") ";
}

ol {
  list-style-type: lower-alpha; // Style par défaut
  list-style: letter-rparen; // Style amélioré, pour les navigateurs compatibles

  li {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 10px;
    }
  }
}


// Espacement des <ul>

#main-container, .full-width {
  ul {
    li {
      margin-bottom: 5px;
    }
  }
}
