//
// Feature Portlet
//

// Variables

@pave1-height: 330px;
@pave1-padding: 10px;

@pave1-img-height-sm: 133px;
@pave1-img-height-md: 165px;
@pave1-img-height-lg: 180px;

@pave1-body-height-sm: 197px; // @pave1-height - @pave1-img-height-sm;
@pave1-body-height-md: 165px; // @pave1-height - @pave1-img-height-md;
@pave1-body-height-lg: 150px; // @pave1-height - @pave1-img-height-lg;

@pave1-font-size-sm: 15px;
@pave1-font-size-md: 15px;
@pave1-font-size-lg: 15px;

@pave1-heading-size-sm: 30px;
@pave1-heading-size-md: 30px;
@pave1-heading-size-lg: 30px;

//Styles

.feature-portlet {
  padding: 0;
  margin-bottom: @fullwidth-spacing-y-xs;
  @media (min-width: @plone-screen-sm-min) {
    margin-bottom: @fullwidth-spacing-y;
  }
  background-color: white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  //filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.6));
  .responsive-value(font-size; @pave1-font-size-sm; @pave1-font-size-md; @pave1-font-size-lg);
  line-height: 1.2em;

  h2 {
    .responsive-value(font-size; @pave1-heading-size-sm; @pave1-heading-size-md; @pave1-heading-size-lg);
  }

  .image {
    position: relative;
    .responsive-value(height; @pave1-img-height-sm; @pave1-img-height-md; @pave1-img-height-lg);
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }

    h2 {
      position: absolute;
      margin-bottom: -1px;
      margin-left: 0;
      padding: 5px 20px 1px @pave1-padding;
      bottom: 0;
      background-color: white;
      font-weight: normal;
      clip-path: polygon(0 0, 92% 0, 100% 46%, 100% 100%, 0 100%, 0 0);
    }
  }

  .body {
    padding: @pave1-padding;
    .responsive-value(height; @pave1-body-height-sm; @pave1-body-height-md; @pave1-body-height-lg);

    h2 {
      margin-top: 0;
      font-weight: 700;
    }
  }

  .footer {
    padding: @pave1-padding;
  }
}

.feature-portlet.feature-portlet-Beige {
  background-color: @rpix-beige;

  .image {
    h2 {
      background-color: @rpix-beige;
    }
  }
}

// Ajustements pour la version française

html[lang="fr"] {
  .feature-portlet {
    .image {
      h2 {
        clip-path: polygon(0 0, 93% 0, 100% 46%, 100% 100%, 0 100%, 0 0);
      }
    }
  }
}
