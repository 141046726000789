//*// SITE NAV //*//

@myaccess-menu-width: 18em;
@lighter-rpix-gold: lighten(@rpix-gold, 15%);

.plone-nav#portal-myaccess,
.plone-navbar-nav#portal-myaccess {

  @media (max-width: @plone-grid-float-breakpoint-max) {
    margin: 0 -1px 0;
  }

  li {

    // Entrées, cas général
    &.has_subtree {
      > label {
        right: 0;
        top: 0;

        &:after {
          position: absolute;
        }
      }
    }
  }

  @media (max-width: @plone-grid-float-breakpoint - 1) {

    // Entrée de 1er niveau, version mobile
    > li.has_subtree {
      > label {
        &::after {
          background-color: @lighter-rpix-gold;
        }
      }

      > ul > li.has_subtree > a {
        pointer-events: none;
      }
    }

    // Entrée générale, version mobile
    li {
      > label {
        &:after {
          border-radius: @plone-input-border-radius;
          cursor: pointer;
          line-height: 1;
          font-size: 1.25em;
          padding: 0.25em;
          top: 0.45em;
          right: 0.5em;
        }
      }

      &:hover {
        > label {
          &:after {
            background-color: @lighter-rpix-gold;
            transition: ease-in 0s;
          }
        }
      }

      a {
        padding-left: 15px; // Todo
      }

      ul {
        padding-left: 1em !important;
      }
    }
  }

  @media (min-width: @plone-grid-float-breakpoint) {

    // Entrée de 1er niveau, version bureau
    > li.has_subtree {
      > label {
        &::after {
          content: "" !important;
        }
      }

      > ul > li.has_subtree > a {
        pointer-events: none;
      }

      .opener {
        &:checked ~ ul {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    // Entrée générale, version bureau
    li {
      > label {
        z-index: 100;

        &:after {
          top: 1.25em;
        }
      }
    }
  }
}
