//*// SITE NAV //*//

// Variante pour le mégamenu

@import "utils";
@import 'sitenav.plone.megamenu.dropdown.less';

.plone-navbar {
  box-shadow: none;
}

.plone-navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.plone-nav-megamenu { // ul

  @media (min-width: @plone-screen-md-min) {
    display: flex;
    flex-direction: row;
  }

  position: relative;
  z-index: 19; // Pour rester derrière la toolbar
  .responsive-value(width; @megamenu-width-sm; @megamenu-width-md; @megamenu-width-lg);
  line-height: 1;
  margin: 0 -@plone-grid-gutter-width;
  padding-left: 0; // Override default ul/ol

  &:extend(.clearfix all);

  a {
    font-size: 15px;
    color: inherit;
  }

  > li#portaltab-home {
    background-color: @rpix-gold;
  }

  > li {

    flex: auto;
    display: block;
    border-left: 1px solid white;

    @media (min-width: @plone-screen-md-min) {
      &:first-child {
        margin-left: @plone-grid-gutter-width;
      }

      &:last-child {
        margin-right: @plone-grid-gutter-width;
      }
    }

    > a {
      display: block;
      padding: @plone-sitenav-link-padding;
      line-height: @plone-line-height-computed;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
    }

    &:last-child {
      border-right: 1px solid white;
    }

    &:hover {
      > a {
        background-color: @rpix-beige;
        color: @black;
        text-decoration: none;

        // Ne pas ouvrir le mégamenu sur un survol
        /*
        + .megamenu-panel {
          display: block !important;
        }
        */
      }
    }
  }

  #portaltab-home {
    img {
      height: 26px;
      margin: -4px 0;

    }

    &:hover {
      img {
        filter: invert(100%);
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: @plone-grid-float-breakpoint) {
    float: left;
    > li {
      float: left;

      > a {
        padding-top: @plone-sitenav-padding-vertical;
        padding-bottom: @plone-sitenav-padding-vertical;
      }

      label {
        font-size: 1em;
        background: transparent;
      }
    }

    &.navbar-right:last-child {
      margin-right: -@plone-sitenav-padding-horizontal;
    }
  }

  button {
    cursor: pointer;
  }

  @import 'sitenav.plone.megamenu.style-4.less';

}
