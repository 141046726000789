//*// HEADER //*//

@hauteur-bandeau: 250px;
@largeur-logo: 400px;
@hauteur-logo: 144px; // approximatif

@media (min-width: @plone-screen-sm-min) {
  #portal-header {
    height: @hauteur-bandeau;
  }
}

#portal-logo {
  img {
    margin-left: 0;
    @media (max-width: @plone-screen-xs-max) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @media (min-width: @plone-screen-sm-min) {
      margin-top: ((@hauteur-bandeau - @hauteur-logo) / 2); // centré verticalement
    }
    max-width: @largeur-logo;
  }
}

#portal-languageselector {
  clear: both;
  display: inline-block;
  float: left;
  margin-right: 29px;
  padding-top: 5px;

  li {
    display: inline-block;
    margin-right: 10px;
  }
}

#portal-anontools {
  float: right;

  ul {
    padding-right: 0;
  }
}

#portal-membertools-wrapper {
  float: right;
  padding: @plone-padding-base-horizontal 0;

  ul {
    margin: 0 0 @plone-padding-base-horizontal @plone-padding-base-horizontal;
    padding-right: 0;
  }
}

//mobile search
#portal-searchbox {
  clear: left;
  position: relative;
  white-space: nowrap;
  z-index: 2;
  @media (min-width: @plone-screen-sm-min) {
    margin-left: 3px;
    margin-top: 60px;
    padding-top: 20px;
  }

  .searchSection {
    display: none;
  }

  //non on mobile
  [type="submit"] {
    display: none;
  }

  //submit hidden on mobile
  label {
    font-size: 86.667%
  }
}

//non mobile search
@media (min-width: @plone-grid-float-breakpoint) {

  #portal-languageselector {
    clear: initial;
    float: initial;
  }

  #portal-searchbox {
    display: inline-block;
    margin-left: 15px;
    margin-top: 5px; //visual balance
    max-width: 50%;
    padding-top: 0;
    position: relative;

    .searchSection {
      display: inherit;
    }

    //yes for non mobile
    [type="text"] {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      float: left;
      width: 20rem;
      height: 30px;
    }

    [type="submit"] {
      display: inline-block;
      margin-left: -1px;
      vertical-align: top;
      width: auto;
      height: 30px;
    }
  }
}
